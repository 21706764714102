import * as React from 'react'
import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import styled from 'styled-components'

import { Query, File } from '@generated/graphql'

import Layout from '@components/Layout'
import UI, { GLink, Grid, Box } from '@components/UI'

const H3 = styled(UI.H3).attrs({
  pb: [2, 3]
})``
const ReleasesContainer = styled(Box).attrs({
  pb: [4, 5]
})``

const ReleasesGrid = styled(Grid).attrs({
  spacing: [2, 2, 3],
  numCols: 3
})``

/**
 * Display grid of releases with each release as a link to its release page
 */
const Releases = ({ releases }: { releases: File[] }) => {
  const getMusicPath = (relativePath: string) =>
    `${relativePath.replace(/[0-9]+_/, '').replace(/\..*$/, '')}`

  return (
    <ReleasesContainer>
      <ReleasesGrid
        elements={releases}
        render={({ relativePath, name, childImageSharp }: File) => {
          // @ts-ignore graphql type doesn't match BackgroundImage type
          const imageFluid: FluidObject = childImageSharp.fluid

          return (
            <GLink to={getMusicPath(relativePath)}>
              <Img
                alt={name}
                key={childImageSharp.fluid.src}
                fluid={imageFluid}
              />
            </GLink>
          )
        }}
      />
    </ReleasesContainer>
  )
}

/**
 * Music page with list of releases by category
 */
const Music = ({ data }: { data: Query }) => {
  const albums = [...data.allFile.group[0].nodes]
  const featured = [...data.allFile.group[1].nodes]
  const singles = [...data.allFile.group[2].nodes]
  console.log(data.allFile.group)

  return (
    <Layout title="Music">
      <H3>FEATURED</H3>
      <Releases releases={featured} />
      <H3>ALBUMS & EPS</H3>
      <Releases releases={albums} />
      <H3>SINGLES & REMIXES</H3>
      <Releases releases={singles} />
    </Layout>
  )
}

export default Music

export const query = graphql`
  {
    allFile(
      filter: {
        absolutePath: { regex: "/content.music/" }
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      group(field: dir) {
        nodes {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
